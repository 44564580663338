import React from "react"
import FreshChat from "react-freshchat"
import { useAuth } from "gatsby-theme-firebase"
// import { trackCustomEvent } from "../../utils/analytics"

export default () => {
  const { profile, isLoading } = useAuth()
  if (isLoading) return null
  const freshChat = (
    <div>
      <FreshChat
        token="f52a3734-93e1-42cb-8939-27dc90443e9b"
        email={profile && profile.email ? profile.email : null}
        firstName={profile && profile.displayName ? profile.displayName : null}
        onInit={widget => {
          /* Use `widget` instead of `window.fcWidget`
        widget.user.setProperties({
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          phone: user.phoneNumber,
        })
      */
        }}
      />
    </div>
  )
  return null //freshChat
  //disable chat for now...
}
